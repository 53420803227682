<div class="row" [hidden]="!error.existError">
  <div class="col-12" [ngClass]="setFontSize()">
    <div
      #alert
      [ngClass]="getAlertClass()"
      class="alert alert-dismissible text-center"
    >
      <h3><i class="icon fas fa-exclamation-triangle"></i> Atenção!</h3>
      <p *ngFor="let item of error.errorList" [innerHtml]="item"></p>
    </div>
  </div>
</div>
