import { Pipe, PipeTransform } from '@angular/core';
import { SimNaoEnum } from '@core/enums/sim-nao.enum';

@Pipe({
  name: 'trueFalse'
})
export class TrueFalsePipe implements PipeTransform {

  private simNaoEnum = new SimNaoEnum();

  transform(value: any): any {
    if (value != null) {
      return this.simNaoEnum.getName(value);
    }
  }

}
