import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FooterStackedChartData } from '../chart-types';

@Component({
  selector: 'app-footer-stacked-chart',
  templateUrl: './footer-stacked-chart.component.html',
  styleUrls: ['./footer-stacked-chart.component.scss'],
})
export class FooterStackedChartComponent implements AfterContentChecked {
  @Input() labelButton = '';
  @Input() data: FooterStackedChartData;
  @Input() disabledButton: boolean = false;
  @Input() isView: boolean = false;
  @Input() customClass: string = '';
  @Input() permiteMutiplaLotacao: boolean;
  @Output() buttonClicked = new EventEmitter<string>();
  @ViewChild('line', { static: false }) divHello: ElementRef;

  ngAfterContentChecked(): void {
    const lineDiv = this.divHello?.nativeElement;
    const linePer = lineDiv?.children[0];

    const values = this.data.values;
    const calculo = values[1] === 0 ? 0 : (values[0] * 100) / values[1];
    let percentual = Math.round(calculo);

    if (this.permiteMutiplaLotacao) {
      percentual = 100;
    }

    if (linePer) {
      linePer.style.width = `${percentual}%`;
    }

    this.setLabelButton();
  }

  onClick(event) {
    this.buttonClicked.emit(event);
  }

  setLabelButton(): void {
    if (this.isView) {
      this.labelButton = 'Visualizar';
      return;
    }

    const values = this.data.values;

    if (values[0] === values[1] || values[0] > values[1]) {
      this.labelButton = 'Editar';
      return;
    }

    this.labelButton = 'Adicionar';
  }
}
