import { Component, Input } from '@angular/core';
import { Routes } from '@angular/router';

@Component({
  selector: 'app-card-menu',
  templateUrl: './card-menu.component.html',
  styleUrls: ['./card-menu.component.scss']
})
export class CardMenuComponent {

  @Input() sortedRoutes: Routes;

}
