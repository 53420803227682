import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Util } from 'src/app/shared/utils/util';

import { INICIO_INFO_MENU, MENUS_INFOBAR } from './navbar-infobar-menu.constant';
import { MENUS_NAVBAR, MenuType } from './navbar-menu.constant';


@Component({
  selector: 'app-navbar-auth',
  templateUrl: './navbar-auth.component.html',
  styleUrls: ['./navbar-auth.component.scss'],
})
export class NavbarAuthComponent {

  public menus = MENUS_NAVBAR;
  public menusInfoBar = MENUS_INFOBAR;
  public usuarioSession = Util.getUsuarioSession();
  public isGre = Util.isUserGRE();

  constructor(public router: Router, public activatedRoute: ActivatedRoute) {}

  get currentInfoMenu() {
    const url = this.router.url.split('/')[1];

    return this.menusInfoBar.find(menu => menu.url === url) ??
      this.menusInfoBar.find(menu => menu.label == INICIO_INFO_MENU);
  }
  get menusNavbar() {
    let menusArray = this.menus.find((menu) => menu.infoMenu === this.currentInfoMenu.label)?.menus || [];

    return menusArray;
  }

  getMenuUrl(menu) {
    let url = '/' + this.currentInfoMenu.url;
    return url + menu.url;
  }

  get ambienteQA() {
    return window.location.href.includes('codatahml');
  }

  shouldShowNavbarItem(item: MenuType): boolean {
    if (!item.profiles){
      return true;
    }

    return item.profiles.some(profile => this.usuarioSession.perfil?.descricao?.toUpperCase().includes(profile.toUpperCase()));
  }
}
