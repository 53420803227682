import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SimNaoEnum } from '@core/enums/sim-nao.enum';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html'
})
export class RadioButtonComponent {

  @Input() options = new SimNaoEnum().getAll();
  @Input() name = '';
  @Input() label = '';
  @Input() id = '';
  @Input() form: FormGroup;
  @Output() onChange = new EventEmitter<any>();

  get idName() {
    return this.id || this.name;
  }

}
