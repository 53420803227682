interface MenuType {
  url?: string;
  label: string;
  exact?: boolean;
  profiles?: string[];
}
export  const SUPORTE_AO_SISTEMA_INFO_MENU = 'Suporte ao Sistema';
export const INICIO_INFO_MENU = 'Início';


export const MENUS_INFOBAR: MenuType[] = [
  {
    url: '',
    label: INICIO_INFO_MENU,
    exact: true
  },
  {
    url: 'suporte',
    label: SUPORTE_AO_SISTEMA_INFO_MENU,
    profiles: ['administrador']
  },
  // {
  //   url: '#',
  //   label: 'Saber',
  // },
  // {
  //   url: '#',
  //   label: 'Ajuda',
  // },
  // {
  //   url: '#',
  //   label: 'Novidades',
  // },
];
