import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * Diretiva para permitir apenas letras e números em um elemento de entrada.
 *
 * @Directive
 * @description Essa diretiva impede que o usuário insira caracteres especiais
 * ou acentos em um elemento de entrada, permitindo apenas letras maiúsculas e
 * minúsculas e números. A diretiva também impede que o usuário cole texto que
 * não esteja em conformidade com essas regras.
 *
 * @usageNotes Aplique essa diretiva a um elemento de entrada usando o seletor `appAlphanumericOnly`.
 *
 * @example
 * <input type="text" appAlphanumericOnly>
 */

@Directive({
  selector: '[appAlphanumericOnly]'
})
export class AlphanumericOnlyDirective {

  @Input() permitirEspaco: boolean = false; // Permitir espaços (opcional)
  @Input() permitirAcentos: boolean = false; // Permitir acentuação (opcional)
  @Input() apenasLetras: boolean = false; // Permitir apenas letras (opcional)

  constructor(private el: ElementRef) {}

  private buildRegex(): RegExp {
    let regexPattern: string;

    if (this.apenasLetras) {
      if (this.permitirEspaco && this.permitirAcentos) {
        regexPattern = '^[a-zA-Z\\sáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$';
      } else if (this.permitirEspaco && !this.permitirAcentos) {
        regexPattern = '^[a-zA-Z\\s]*$';
      } else if (!this.permitirEspaco && this.permitirAcentos) {
        regexPattern = '^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$';
      } else {
        regexPattern = '^[a-zA-Z]*$';
      }
    } else {
      if (this.permitirEspaco && this.permitirAcentos) {
        regexPattern = '^[a-zA-Z0-9\\sáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$';
      } else if (this.permitirEspaco && !this.permitirAcentos) {
        regexPattern = '^[a-zA-Z0-9\\s]*$';
      } else if (!this.permitirEspaco && this.permitirAcentos) {
        regexPattern = '^[a-zA-Z0-9áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$';
      } else {
        regexPattern = '^[a-zA-Z0-9]*$';
      }
    }

    return new RegExp(regexPattern);
  }

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent): void {
    const inputChar = String.fromCharCode(event.keyCode || event.charCode);
    const regex = this.buildRegex();

    if (!regex.test(inputChar)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    const regex = this.buildRegex();

    if (!regex.test(pastedText)) {
      event.preventDefault();
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const key = event.key;
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];

    if (allowedKeys.includes(key)) {
      return; // Permitir teclas de navegação e exclusão
    }

    const inputValue = this.el.nativeElement.value;
    const regex = this.buildRegex();

    if (!regex.test(inputValue + key)) {
      event.preventDefault();
    }
  }
}
