<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-custom">
  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user panel (optional) -->
    <div class="user-panel">
      <div class="user-info">
        <div class="info">
          <img class="img-fluid mx-auto d-block" style="width: 100%; height: 100%;" src="assets/img/logo-gov-pb.png"
            alt="Logo da secretaria">
        </div>
      </div>
    </div>
    <hr>
    <div class="user-panel">
      <div class="user-info">
        <div class="info">
          <p class="name-user">{{ usuarioSession.nome }}</p>
          <span class="name-profile">{{ perfilDescription }} </span>
        </div>
      </div>
    </div>
    <hr>
    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
        <li class="nav-item">
          <a [routerLink]="[checkRotaInicio()]" class="nav-link">
            <em class="nav-icon fas fa-home"></em>
            <p> INÍCIO </p>
          </a>
        </li>

        <li class="nav-item has-treeview">
          <a href="javascript:void(0)" class="nav-link">
            <em class="nav-icon fas fa-sliders-h"></em>
            <p>
              CONFIGURAÇÕES
              <em class="right fas fa-angle-left"></em>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="['/configuracao/perfis']" routerLinkActive="router-link-active" class="nav-link">
                <em class="nav-icon far fa-circle nav-icon"></em>
                <p>PERFIS</p>
              </a>
            </li>

            <li class="nav-item">
              <a [routerLink]="['/configuracao/usuario']" routerLinkActive="router-link-active" class="nav-link">
                <em class="nav-icon far fa-circle nav-icon"></em>
                <p>USUÁRIOS</p>
              </a>
            </li>

            <li class="nav-item" >
              <a [routerLink]="['/configuracao/tipo-unidade']" routerLinkActive="router-link-active"  class="nav-link">
                <em class="nav-icon far fa-circle nav-icon"></em>
                <p>TIPO UNIDADE</p>
              </a>
            </li>

            <li class="nav-item has-treeview" >
              <a href="javascript:void(0)" class="nav-link" title="Saber">
                <em class="nav-icon far fa-circle nav-icon"></em>
                <p>
                  SABER
                  <em class="right fas fa-angle-left"></em>
                </p>
              </a>
              <ul class="nav nav-treeview">

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/atividade-aee']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>ATIVIDADES AEE</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/atividade-complementar']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>ATIVIDADES COMPLEMENTARES</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/area']" routerLinkActive="router-link-active" class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>ÁREA</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/curso-formacao-especifica']"
                    routerLinkActive="router-link-active" class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>CURSOS DE FORMAÇÃO ESPECÍFICA</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/distritos']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>DISTRITOS</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/destinatario']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>DESTINATÁRIO</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/equipamentos']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>EQUIPAMENTOS</p>
                  </a>
                </li>

                <li class="nav-item" >
                  <a [routerLink]="['/configuracao/saber/ferramentas']" routerLinkActive="router-link-active"  class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>FERRAMENTAS</p>
                  </a>
                </li>

                <li class="nav-item" >
                  <a [routerLink]="['/configuracao/saber/gerencias-regionais']" routerLinkActive="router-link-active"  class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>GERÊNCIAS REGIONAIS</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/manter-meios-transportes']" routerLinkActive="router-link-active"
                  class="nav-link">
                  <em class="nav-icon far fa-dot-circle"></em>
                  <p>MEIOS DE TRANSPORTE</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/manter-manuais']" routerLinkActive="router-link-active"
                  class="nav-link">
                  <em class="nav-icon far fa-dot-circle"></em>
                  <p>MANUAIS</p>
                </a>
              </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/material']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>MATERIAL</p>
                  </a>
                </li>

                <li class="nav-item" >
                  <a [routerLink]="['/configuracao/saber/mobiliarios']" routerLinkActive="router-link-active"  class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>MOBILIÁRIO</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/municipios']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>MUNICÍPIOS</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/manter-necessidade']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>NECESSIDADES ESPECIAIS</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/programa']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>PROGRAMAS</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/recursos-necessarios']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>RECURSOS NECESSÁRIOS</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/referencia-material']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>REFERÊNCIA MATERIAL</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/subarea']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>SUBÁREA</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/tipo-documento']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>TIPOS DE DOCUMENTOS</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/tipo-espaco-fisico']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>TIPOS DE ESPAÇOS FÍSICOS</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/tipo-material']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>TIPO DE MATERIAL</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/tipo-programa']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>TIPO DE PROGRAMA</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/novidade']" routerLinkActive="router-link-active"
                    class="nav-link">
                    <em class="nav-icon far fa-dot-circle"></em>
                    <p>NOVIDADES</p>
                  </a>
                </li>

                <li class="nav-item">
                  <a [routerLink]="['/configuracao/saber/manter-questionarios']" routerLinkActive="router-link-active"
                  class="nav-link">
                  <em class="nav-icon far fa-dot-circle"></em>
                  <p>QUESTIONARIOS</p>
                  </a>
                </li>

              </ul>
            </li>
          </ul>
        </li>

      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->

  <!-- Brand Logo -->
  <hr>
  <a href="javascript:void(0)" class="brand-link">
    <span class="brand-text">
      e-Saber &copy;
      <br />
      <span>versão 1.0.0</span>
    </span>
  </a>

</aside>
