<div class="wrapper">
  <!-- Componente de Sidebar -->
  <!-- End Componente de Sidebar -->
  <div>
    <!-- Componente de Navbar -->
    <app-navbar-auth></app-navbar-auth>
    <!-- End Componente de Navbar -->

    <div class="content-wrapper">
      <!-- Content header -->
      <div class="content-header"></div>
      <!-- End Content Header -->

      <!-- Content -->
      <div class="content">
        <app-page-title></app-page-title>
        <div class="marca-dagua" *ngIf="ambienteQA">
          <h2>
            AMBIENTE DE HOMOLOGAÇÃO / TREINAMENTO
          </h2>
        </div>

        <router-outlet></router-outlet>
      </div>
      <!-- End Content -->
    </div>

    <!-- Componente end Footer -->
    <!-- Componente de Footer-->
    <app-footer></app-footer>
  </div>
</div>
