import { ValidateMessages } from 'src/app/core/interfaces/validate-messages';
import { Util } from './util';

export class ValidationMessages implements ValidateMessages {

  messages = Util.getValidateMessages();
  /**
   * Mensagens de validação
   */
  static msg() {
    return Util.getValidateMessages();
  }

  getMessages() {
    return this.messages;
  }
}
